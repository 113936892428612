export const tabMenu = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const tabs = document.querySelectorAll('[role="tab"]'),
      tabpanels = document.querySelectorAll('[role="tabpanel"]');

    if (tabs.length === 0 || tabpanels.length === 0) return;

    tabpanels.forEach((panel) => (panel.style.display = "none"));
    tabs[0].classList.add("_is-active");
    tabpanels[0].style.display = "block";
    tabpanels[0].setAttribute("aria-hidden", "false");

    tabs.forEach((tab, i) => {
      tab.addEventListener("click", function (e) {
        e.preventDefault();
        const select = this.getAttribute("aria-selected");
        if (select === "true") return false;

        const id = this.getAttribute("aria-controls");
        const id_body = document.querySelector("#" + id);

        tabpanels.forEach((panel) => {
          panel.setAttribute("aria-hidden", "true");
          panel.style.display = "none";
        });

        id_body.setAttribute("aria-hidden", "false");
        id_body.style.display = "block";

        tabs.forEach((t) => {
          t.setAttribute("aria-selected", "false");
          t.classList.remove("_is-active");
          t.setAttribute("tabindex", "-1");
        });

        this.classList.add("_is-active");
        this.setAttribute("aria-selected", "true");
        this.setAttribute("tabindex", "0");
        this.focus();
      });

      tab.addEventListener("keydown", function (e) {
        let index = Array.prototype.indexOf.call(tabs, this);
        let dir = 0;
        if (e.which === 37) {
          dir = index - 1;
        } else if (e.which === 39) {
          dir = index + 1;
        } else if (e.which === 35) {
          dir = tabs.length - 1;
        } else if (e.which === 36) {
          dir = 0;
        } else {
          return;
        }

        if (dir < 0) {
          dir = tabs.length - 1;
        } else if (dir >= tabs.length) {
          dir = 0;
        }

        tabs[dir].click();
        tabs[dir].focus();
      });
    });
  });
};
