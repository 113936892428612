import { toggleHamburger } from "./toggleHamburger";
import { mvSlider } from "./sliders";
import { accordion } from "./accordion";
import { tabMenu } from "./tabMenu";
toggleHamburger("js-hamburger", "js-spmenu", 1024);
accordion();
tabMenu();

const splideSliderElement = document.querySelector(".js-splide");
if (splideSliderElement) {
  const splideSlider = new Splide(splideSliderElement, {
    breakpoints: {
      768: {},
    },
    type: "loop",
    pagination: true,
    rewind: true,
    focus: "center",
    autoWidth: true,
    gap: "30px",
    perMove: 1,
    flickMaxPages: 1,
    flickPower: 50,
  }).mount();
  splideSlider.on("resize", () => {
    splideSlider.refresh();
  });
}
